import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

const Spin = () => {
    return (
        <Box pos="fixed" top="50%" right="50%">
            <Spinner size="lg" />
        </Box>
    )
}

export default Spin;