import React from "react";
import { Formik, Form, Field } from "formik";
import { navigate } from "gatsby";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box,
    Flex,
    Spacer,
    Button,
    Heading,
    Select,
    Input,
    InputGroup,
    InputLeftAddon,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText
} from "@chakra-ui/react";
import Recaptcha from "react-recaptcha";
import { FcGoogle } from "react-icons/fc";
import { SiFacebook } from "react-icons/si";

import HomeButton from "../components/home";
import Spin from "../components/spin";
import { useHasMounted } from "../components/hooks";
import Footer from "../components/footer";

import getFirebase from "../utils/firebase";
import UserMgr from "../utils/user_manager";
import { vitalizeConstants } from "../utils/constants";
const UMObj = UserMgr.getInstance();


const SignIn = (props) => {
    //console.log(props);
    const validateUserSignIn = (value) => {
        let error;
        if (!value) {
            error = "Required";
        }
        return error;
    }
    
    const validatePWSignIn = (value) => {
        let error;
        if (!value) {
            error = "Required";
        }
        return error;
    }
        
    return (
        <Formik
            initialValues={{ username: "", pwSI: "" }}
            onSubmit={(values, actions) => {
                console.log(JSON.stringify(values, null, 2));
                props.fb.authModule.signInWithEmailAndPassword(props.fb.auth, values.username, values.pwSI)
                .then(() => {
                    // Signed in 
                    actions.resetForm();
                    actions.setSubmitting(false);
                    navigate("/?status=signin_success");
                })
                .catch(error => {
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    // console.log(errorCode)
                    // console.log(errorMessage)
                    toast.error(`Login failed: ${error.message}`, {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
        
                    // ..
                    actions.setFieldError("pwSI", "Incorrect username/password");
                    actions.setSubmitting(false);
                })  
            }}
        >
            {(props) => (
            <Form>
                <Box mt={3}>
                    <Field name="username" validate={validateUserSignIn}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.username && form.touched.username} width="100%" isRequired>
                            <Input {...field} id="username" placeholder="Email address" variant="filled" />
                            <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                            {/* <FormHelperText>Standard SMS rates apply for Phone login</FormHelperText> */}
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field name="pwSI" validate={validatePWSignIn}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.pwSI && form.touched.pwSI} width="100%" isRequired>
                            <Input {...field} id="pwSI" placeholder="Password" variant="filled" type="password"/>
                            <FormErrorMessage>{form.errors.pwSI}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Button
                        mt={4}
                        align="center"
                        colorScheme="teal"
                        isLoading={props.isSubmitting}
                        type="submit"
                    >
                        Submit
                    </Button>
                </Box>
            </Form>
            )}
        </Formik>
    )
}

const SignUp = (props) => {
    console.log(props);
    const validateName = (value) => {
        let reg = /\w+\s*/;
        let error;
        if (!value || !reg.test(value)) {
            error = "Required";
        }
        return error;
    }

    const validateEmail = (value) => {
        let reg = /^\w[\w.-]*@([\w-]+\.)+[\w-]+$/;
        let error;
        if (!value) {
            error = "Required";
        }
        else if (!reg.test(value)) {
            error = "Please input valid email address";
        }
        return error;
    }

    const validatePhone = (value) => {
        let reg = /^(2|3|4|6|7|9|50|52|54|56|58)\d{7}$/;
        let error;
        if (!value) {
            error = "Required";
        }
        else if (!reg.test(value) || value === "41234567") {
            error = "Please input valid UAE phone/mobile number";
        }
        return error;
    }

    const validateEmirate = (value) => {
        let error;
        if (!value || value === "-") {
            error = "Required";
        }
        return error;
    }

    const validatePassword = (value) => {
        let reg = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
        let error;
        if (!value) {
            error = "Required";
        }
        else if (!reg.test(value)) {
            error = "Please choose a strong password";
        }
        return error;
    }

    const validateRecaptcha = (value) => {
        let error;
        if (!value) {
            error = "Required";
        }
        else if (value.includes("robot") || value.includes("failed")) {
            error = value;
        }
        return error;
    }

    return (
        <Formik
            initialValues={{ name: "", email: "", phone: "", adline1: "", adline2: "", emirate: "", pwSU: "", reCap: "" }}
            onSubmit={(values, actions) => {

                // First query if user exists in DB
                const usersRef = props.fb.fsModule.collection(props.fb.fs, "users");
                const query1 = props.fb.fsModule.query(usersRef,
                    props.fb.fsModule.where("email", "==", values.email),
                );
                const query2 = props.fb.fsModule.query(usersRef,
                    props.fb.fsModule.where("phone", "==", "+971" + values.phone)
                );
                props.fb.fsModule.getDocs(query1)
                .then(snaps1 => {
                    props.fb.fsModule.getDocs(query2)
                    .then(snaps2 => {

                        console.log("Email found:", !snaps1.empty)
                        console.log("Phone found:", !snaps2.empty)

                        // If either email or phone was found in DB
                        if (!snaps1.empty || !snaps2.empty) {
                            // TODO: fix how this is handled
                            actions.resetForm();
                            actions.setFieldError("pwSU", "User already exists");
                            actions.setSubmitting(false);
                            Promise.resolve();
                        }

                        props.fb.authModule.createUserWithEmailAndPassword(props.fb.auth, values.email, values.pwSU)
                        .then(userCredential => {
                            // Signed in 
                            const user = userCredential.user;
                            console.log("NEW USER: ", user);

                            // Update Profile
                            props.fb.authModule.updateProfile(user, {
                                displayName: values.name,
                                //phoneNumber: "+16505550101"
                            })

                            // Add new user to DB
                            props.fb.fsModule.setDoc(props.fb.fsModule.doc(props.fb.fs, "users", user.uid), {
                                uid: user.uid,
                                name: values.name,
                                email: values.email,
                                emailVerified: user.emailVerified,
                                phone: "+971" + values.phone,
                                phoneVerified: false,
                                adline1: values.adline1,
                                adline2: values.adline2,
                                emirate: values.emirate,
                                pw: values.pwSU,
                                reCap: values.reCap,
                                profilePicUrl: null,
                                createdAt: user.metadata.createdAt,
                                creationTime: user.metadata.creationTime,
                                orders: []
                            })
                            .then(() => {
                                actions.resetForm();
                                actions.setSubmitting(false);
                                navigate("/?status=signin_success");
                            })
                            .catch(error => {
                                // const errorCode = error.code;
                                // const errorMessage = error.message;
                                // console.error(error)
                                // console.error(errorMessage)
                                toast.error(`Registration failed: ${error || error.message}`, {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                // ..
                                actions.resetForm();
                                actions.setSubmitting(false);
                            })
                        })
                        .catch(error => {
                            // const errorCode = error.code;
                            // const errorMessage = error.message;
                            // console.error(error)
                            // console.error(errorMessage)
                            toast.error(`Registration failed: ${error || error.message}`, {
                                position: "bottom-center",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            // ..
                            actions.resetForm();
                            actions.setSubmitting(false);
                        })
                    })
                    .catch(error => {
                        // const errorCode = error.code;
                        // const errorMessage = error.message;
                        // console.error(error)
                        // console.error(errorMessage)
                        toast.error(`Registration failed: ${error || error.message}`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // ..
                        actions.resetForm();
                        actions.setSubmitting(false);
                    })
                })
            }}
        >
            {(props) => (
            <Form>
                <Box mt={3}>
                    <Field name="name" validate={validateName}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.name && form.touched.name} width="100%" isRequired>
                            <FormLabel htmlFor="name">Name</FormLabel>
                            <Input {...field} id="name" placeholder="Jane Doe" variant="filled" />
                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field name="email" validate={validateEmail}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.email && form.touched.email} width="100%" isRequired>
                            <FormLabel htmlFor="name">Email address</FormLabel>
                            <Input {...field} id="email" placeholder="customer@example.com" variant="filled" />
                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                            <FormHelperText>Please remember to visit your Profile and verify your email to access checkout!</FormHelperText>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field mt={1} name="phone" validate={validatePhone}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.phone && form.touched.phone} width="100%" isRequired>
                            <FormLabel htmlFor="name">Phone number</FormLabel>
                            <InputGroup>
                                <InputLeftAddon children="+971" />
                                <Input {...field} id="phone" placeholder="41234567" variant="filled" />
                            </InputGroup>
                            <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                            <FormHelperText>Please verify your phone number as well. Helps us contact you regarding the status of your order</FormHelperText>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field name="adline1" validate={validateName}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.adline1 && form.touched.adline1} width="100%" isRequired>
                            <FormLabel htmlFor="name">Address Line 1</FormLabel>
                            <Input {...field} id="adline1" placeholder="415 Skyline Building" variant="filled" />
                            <FormErrorMessage>{form.errors.adline1}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field name="adline2">
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.adline2 && form.touched.adline2} width="100%">
                            <FormLabel htmlFor="name">Address Line 2</FormLabel>
                            <Input {...field} id="adline2" placeholder="Sheikh Zayed Rd" variant="filled" />
                            <FormErrorMessage>{form.errors.adline2}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field name="emirate" validate={validateEmirate}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.emirate && form.touched.emirate} width="100%" isRequired>
                            <FormLabel htmlFor="name">Emirate</FormLabel>
                            <Select {...field} placeholder="-" variant="filled">
                                {vitalizeConstants.SUPPORTED_EMIRATES.map((emirate, i) =>
                                    <option key={"emi"+i}>{emirate}</option>
                                )}
                            </Select>
                            <FormErrorMessage>{form.errors.emirate}</FormErrorMessage>
                            <FormHelperText>Currently we deliver only to the list above</FormHelperText>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field mt={1} name="pwSU" validate={validatePassword}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.pwSU && form.touched.pwSU} width="100%" isRequired>
                            <FormLabel htmlFor="name">Password</FormLabel>
                            <Input {...field} id="pwSU" variant="filled" type="password"/>
                            <FormErrorMessage>{form.errors.pwSU}</FormErrorMessage>
                            <FormHelperText>Minimum 8 characters, 1 uppercase letter and 1 digit</FormHelperText>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Field mt={1} name="reCap" validate={validateRecaptcha}>
                        {({ field, form }) => (
                        <FormControl isInvalid={form.errors.reCap && form.touched.reCap} width="100%" isRequired>
                            <Recaptcha
                                {...field}
                                sitekey={process.env.RECAPTCHA_SITE_KEY_V2}
                                render="explicit"
                                theme="light"
                                verifyCallback={async (challenge) => {
                                    //console.log(response)
                                    //navigate("/checkRecaptcha?response=" + encodeURIComponent(response));
                                    const response = await fetch(process.env.URL_SERVER_RECAPTCHA + "?challenge=" + encodeURIComponent(challenge), {
                                        method: "POST",
                                    });
                                    const reCapResponse = await response.json();
                                    //console.log("ReCap response", reCapResponse.result);
                                    props.setFieldValue("reCap", reCapResponse.result);
                                }}
                                onloadCallback={() => { console.log("done loading recaptcha!"); }}
                            />
                            <FormErrorMessage>{form.errors.reCap}</FormErrorMessage>
                        </FormControl>
                        )}
                    </Field>
                </Box>
                <Box mt={3}>
                    <Button
                        mt={4}
                        align="center"
                        colorScheme="teal"
                        isLoading={props.isSubmitting}
                        type="submit"
                    >
                        Submit
                    </Button>
                </Box>
            </Form>
            )}
        </Formik>    
    )
}

const LoginPage = (props) => {
    const [authModule, setAuthModule] = React.useState(null);
    const [auth, setAuth] = React.useState(null);
    const [fsModule, setFSModule] = React.useState(null);
    const [fs, setFS] = React.useState(null);

    const hasMounted = useHasMounted();
    if (hasMounted) {
        // Load fonts for fed UI
        import("@fontsource/roboto");
        import("@fontsource/inter");

        // reCaptcha script
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.type = "text/javascript";
        document.body.appendChild(script);

        // Firebase dynamic import
        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/firestore"));
        fbImportPromises.push(import("firebase/analytics"));
      
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbFSModule, fbAnalyticsModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const fs = fbFSModule.getFirestore(fbApp);
            const analytics = fbAnalyticsModule.getAnalytics(fbApp);
            UMObj.SetAnalytics(analytics, fbAnalyticsModule);

            setAuthModule(fbAuthModule);
            setAuth(auth);
            setFSModule(fbFSModule);
            setFS(fs);
        })
    }

    const handleGoogleClick = () => {       
        console.log("GLOGIN")   
        // Start a sign in process for an unauthenticated user.
        const provider = new authModule.GoogleAuthProvider(auth);
        provider.addScope("profile");
        provider.addScope("email");
        //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
        authModule.signInWithPopup(auth, provider)
        .then(result => {
            console.log(result)
            // This gives you a Google Access Token.
            // var token = result.credential.accessToken;
            // // The signed-in user info.
            var user = result.user;
            console.log("USERGOOG:", user);
            // First query if user exists in DB
            const usersRef = fsModule.collection(fs, "users");
            const queryEmail = fsModule.query(usersRef,
                fsModule.where("email", "==", user.email),
            );
            fsModule.getDocs(queryEmail)
            .then(snaps => {
                if (snaps.empty) {
                    // Add new user to DB
                    fsModule.setDoc(fsModule.doc(fs, "users", user.uid), {
                        uid: user.uid,
                        name: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        phone: null,
                        phoneVerified: false,
                        adline1: null,
                        adline2: null,
                        emirate: null,
                        pw: null,
                        reCap: "Google Login",
                        profilePicUrl: user.photoURL,
                        createdAt: user.metadata.createdAt,
                        creationTime: user.metadata.creationTime,
                        orders: []
                    })
                }
            })
            .then(() => navigate("/?status=signin_success"));
        })
        .catch(err => {
            toast.error(`Could not log in: ${err.message}`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            //console.error(err)
        })
    }

    const handleFacebookClick = () => {       
        console.log("FBLOGIN")   
        // Start a sign in process for an unauthenticated user.
        const provider = new authModule.FacebookAuthProvider(auth);
        provider.addScope("public_profile");
        provider.addScope("email");
        //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
        authModule.signInWithPopup(auth, provider)
        .then(result => {
            console.log(result);
            // The signed-in user info.
            const user = result.user;
            console.log("USERFB:", user);

            // TODO: find a way to use this
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = authModule.FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;

            // First query if user exists in DB
            const usersRef = fsModule.collection(fs, "users");
            const queryEmail = fsModule.query(usersRef,
                fsModule.where("email", "==", user.email),
            );
            fsModule.getDocs(queryEmail)
            .then(snaps => {
                if (snaps.empty) {
                    // Add new user to DB
                    fsModule.setDoc(fsModule.doc(fs, "users", user.uid), {
                        uid: user.uid,
                        name: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        phone: null,
                        phoneVerified: false,
                        adline1: null,
                        adline2: null,
                        emirate: null,
                        pw: null,
                        reCap: "Facebook Login",
                        profilePicUrl: user.photoURL,
                        createdAt: user.metadata.createdAt,
                        creationTime: user.metadata.creationTime,
                        orders: []
                    })
                }
            })
            .then(() => navigate("/?status=signin_success"));
        })
        .catch(err => {
            toast.error(`Could not log in: ${err.message}`, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            //console.error(err)
        })
    }

    if(!auth) return <Spin />
    else return (
        <>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <HomeButton />
        <Flex
            mt={2}
            py={6}
            bgColor="gray.300"
            rounded={6}
            direction="column"
        >
            <Heading align="center" mb={6}>Login</Heading>
            <Box px={{base: 4, sm: "30%", md: "35%"}} alignContent="center">
                <Flex
                    w="100%"
                    direction="column"
                >
                    <Button
                        onClick={handleGoogleClick}
                        leftIcon={<FcGoogle />}
                        fontFamily="Roboto"
                    >
                        Sign in with Google
                    </Button>
                    <Spacer />
                    <Button
                        mt={3}
                        onClick={handleFacebookClick}
                        leftIcon={<SiFacebook color="white"/>}
                        textColor="white"
                        fontFamily="Inter"
                        colorScheme="messenger"
                    >
                        Continue with Facebook
                    </Button>
                </Flex>
            </Box>
            <Box px={{base: 4, sm: "30%"}}>
                <SignIn fb={{authModule: authModule, auth: auth, fsModule: fsModule, fs: fs}} />
            </Box>
        </Flex>
        <Heading size="md" align="center" my={2}>OR</Heading>
        <Flex
            mb={20}
            py={6}
            bgColor="gray.300"
            rounded={6}
            direction="column"
        >
            <Heading align="center" mb={6}>Register with Vitalize Fresh</Heading>
            <Box px={{base: 4, sm: "30%"}}>
                <SignUp fb={{authModule: authModule, auth: auth, fsModule: fsModule, fs: fs}} />
            </Box>
        </Flex>
        <Footer />
        </>
    )
}

export default LoginPage;

